.preloader-logo {
    @media (max-width: 720px) {
        width: 230px !important;
        height: 43px !important;
    }
}

.header {
    @media (max-width: 720px) {
        padding: 28px 0px 28px 0px;
        margin-bottom: 119px;
    }

    &-inner {
        @media (max-width: 720px) {
            padding-left: 32px;
            padding-right: 32px;
        }
    }

    &-logo {
        @media (max-width: 720px) {
            width: 107px;
            height: 32px;
        }
    }
}

.sidebar {
    @media (max-width: 720px) {
        padding: 36px 32px 48px 32px;
    }

    &-top {
        @media (max-width: 720px) {
            margin-bottom: 84px;
        }
    }

    &-text-color {
        @media (max-width: 720px) {
            font-size: 32px;
            line-height: 48px;
        }
    }
}

.hero {
    @media (max-width: 720px) {
        height: 100vh;
        background-repeat: no-repeat;
        background-position: center;
        padding-bottom: 199px;
        background-size: cover;
        background-image: url(/src/images/hero_bg_mobile.webp);
    }

    &-title {
        @media (max-width: 720px) {
            font-size: 32px;
            line-height: 48px;
        }

        &-img {
            @media (max-width: 720px) {
                width: 230px;
            }
        }
    }


    &-text {
        @media (max-width: 720px) {
            font-size: 14px;
            line-height: 21px;
            margin-bottom: 32px;
        }
    }

    &-btn {
        @media (max-width: 720px) {
            padding: 12px 24px !important;
        }

        &-text {
            @media (max-width: 720px) {
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
}

.begin {
    @media (max-width: 720px) {
        padding: 96px 32px 96px 32px;
    }
    @media (max-width: 380px) {
        padding: 96px 26px 96px 26px;
    }

    &-title {
        @media (max-width: 720px) {
            margin-bottom: 8px;
            font-size: 32px;
            line-height: 48px;
        }
    }

    &-subject {
        @media (max-width: 720px) {
            margin-bottom: 32px;
            font-size: 32px;
            line-height: 48px;
        }
    }

    &-text {
        @media (max-width: 720px) {
            font-size: 24px;
            line-height: 36px;
        }
    }
    .reveal-type{
        
        @media (max-width: 720px) {
            font-size: 24px;
            line-height: 36px;
        }
        @media (max-width: 400px) {
            font-size: 21px;
        }
    }
}

.service {
    &-box {
        @media (max-width: 720px) {
            width: 320px;
            &:last-child{
                margin-right: 24px;
            }
        }

        &-titlev2 {
            @media (max-width: 720px) {
                margin-bottom: 73px !important;
            }
        }

        &-titlev3 {
            @media (max-width: 720px) {
                margin-bottom: 34px;
            }
        }

        &-desc {
            @media (max-width: 720px) {
                font-size: 16px;
                line-height: 24px;
            }
        }

    }

}
.video{
    @media (max-width: 720px) {
        padding-bottom: 60px;
    }
    &-desktop{
        @media (max-width: 720px) {
            display: none;
        }
    }
    &-mobile{
        @media (max-width: 720px) {
            display: block;
        }
    }
}
.question {
    @media (max-width: 720px) {
        padding: 96px 32px;
    }

    &-title {
        @media (max-width: 720px) {
            font-size: 32px;
            line-height: 48px;
            margin-bottom: 48px;
        }
    }

    &-box {
        @media (max-width: 720px) {
            margin-bottom: 128px;
        }
    }

    &-list {
        @media (max-width: 720px) {
            padding: 24px;
        }
    }

    &-text {
        @media (max-width: 720px) {
            font-size: 16px;
            line-height: 24px;
            text-align: left;
            margin-right: 40px;
        }
    }

    &-hidden {
        @media (max-width: 720px) {
            font-size: 14px;
            line-height: 21px;
        }
    }

    &-add {
        @media (max-width: 720px) {
            width: 24px;
            height: 24px;
        }
    }

    &-close {
        @media (max-width: 720px) {
            width: 24px;
            height: 24px;
        }
    }

    &-img {
        @media (max-width: 990px) {
            padding: 64px 32px;
            border-radius: 20px;
        }

        &-title {
            @media (max-width: 720px) {
                font-size: 32px;
                line-height: 48px;
                margin-bottom: 32px;
            }
        }
    }

    // &-parallax {
    //     @media (max-width: 720px) {
    //         display: none;
    //     }
    // }

    // &-parallax-mobile {
    //     @media (max-width: 720px) {
    //         display: block;
    //     }
    // }
}

.footer {
    @media (max-width: 720px) {
        padding-right: initial;
        padding-left: initial;
        flex-wrap: wrap;
        padding-bottom: 16px;
    }

    &-right {
        @media (max-width: 720px) {
            width: 100%;
            margin-bottom: 55px;
            padding-left: 32px;
        }

        &-text {
            @media (max-width: 720px) {
                font-size: 32px !important;
                line-height: 48px;
                margin-bottom: 16px !important;
            }
        }
    }

    &-left {
        @media (max-width: 720px) {
            width: 100%;
            margin-right: inherit;
            padding-right: 16px;
            padding-left: 16px;
        }

        &-address {
            @media (max-width: 720px) {
                padding: 24px;
            }
        }

        &-telephone {
            @media (max-width: 720px) {
                padding: 24px;
            }
        }

        &-email {
            @media (max-width: 720px) {
                padding: 24px;
            }
        }

        &-title {
            @media (max-width: 720px) {
                font-size: 14px;
                line-height: 21px;
            }
        }

        &-text {
            @media (max-width: 720px) {
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
}